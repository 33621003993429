import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col,Modal, Button, Form } from 'react-bootstrap'
import * as actionExpenses from '../store/actions/balance'
import { useSelector, useDispatch} from 'react-redux'
import logo from '../assets/image/logo.jpg'


const Balance = props => {

    let f = new Date();
    let fecha = f.getDate() + "/" + (f.getMonth() +1) + "/" + f.getFullYear();

    let history = useHistory();
    function historyhandleClick() {
      history.push("/account");
    }

    function historyhandleClickData2() {
        history.push("/account");
      }

    const dispatch = useDispatch()
    const [dataexpenses, setDataexpenses] = useState([])
    const [dataexpensesRegular, setDataexpensesRegular] = useState([])

    const expenses = useSelector(state => state.expenses.expenses)

    const [sumCash, setSumCash] = useState('')
    const [sumDebit, setSumDebit] = useState('')
    const [sumCredit, setSumCredit] = useState('')

    const [sumCashRegular, setSumCashRegular] = useState('')
    const [sumDebitRegular, setSumDebitRegular] = useState('')
    const [sumCreditRegular, setSumCreditRegular] = useState('')

    useEffect(() => {

        const commonData = expenses?.filter(c => c.category === 'common')

        setDataexpenses(commonData)       

        const resumecommonCash = expenses?.filter(s =>   s.category === 'common' && s.payment === 'Cash').map(s =>   parseFloat(s.total))
        const resumecommonDebit = expenses?.filter(s =>  s.category === 'common' && s.payment === 'Debit').map(x =>  parseFloat(x.total))
        const resumecommonCredit = expenses?.filter(s => s.category === 'common' && s.payment === 'Credit').map(x => parseFloat(x.total))
        
        if(resumecommonCash?.length >= 1) {
            let sumCash = 0
              for (let i = 0; i < resumecommonCash.length; i++) {
                  sumCash += resumecommonCash[i];
              }
              setSumCash(sumCash)
        }

        if(resumecommonDebit?.length >= 1) {
            let sumdebit = 0
            for (let i = 0; i < resumecommonDebit.length; i++) {
                sumdebit += resumecommonDebit[i];
            }
            setSumDebit(sumdebit)
        }

        if(resumecommonCredit?.length >= 1) {
            let sumcredit = 0
             for (let i = 0; i < resumecommonCredit.length; i++) {
                 sumcredit += resumecommonCredit[i];
             }
             setSumCredit(sumcredit)
        }       

       

    },[setDataexpenses, expenses])


    useEffect(() => {

        const regularData = expenses?.filter(r => r.category === 'regular')

        setDataexpensesRegular(regularData)

        const resumecommonCashRegular = regularData?.filter(s =>   s.payment === 'Cash').map(x =>   parseFloat(x.total))
        const resumecommonDebitRegular = regularData?.filter(s =>  s.payment === 'Debit').map(x =>  parseFloat(x.total))
        const resumecommonCreditRegular = regularData?.filter(s => s.payment === 'Credit').map(x => parseFloat(x.total))
       if(resumecommonCashRegular?.length >= 1) {
        let sumcashregular = 0
        for (let i = 0; i < resumecommonCashRegular.length; i++) {
            sumcashregular += resumecommonCashRegular[i];
        }
        setSumCashRegular(sumcashregular)
       }

       if(resumecommonDebitRegular?.length >= 1){
        let sumdebitRegular = 0
        for (let i = 0; i < resumecommonDebitRegular.length; i++) {
            sumdebitRegular += resumecommonDebitRegular[i];
        }
        setSumDebitRegular(sumdebitRegular)
       }

        if(resumecommonCreditRegular?.length >= 1){
            let sumcreditRegular = 0
        for (let i = 0; i < resumecommonCreditRegular.length; i++) {
            sumcreditRegular += resumecommonCreditRegular[i];
        }
        setSumCreditRegular(sumcreditRegular)
        }
    },[setDataexpensesRegular, expenses])

//button principal
    const [openExpenseCommon, setOpenExpenseCommon] = useState(false)
    const [openExpenseRegular, setOpenExpenseRegular] = useState(false)

    function showExpensesCommon() {
        setOpenExpenseCommon(prev => !prev)
        setOpenExpenseRegular(false)

    }

    function showExpensesRegular() {
        setOpenExpenseRegular(prev => !prev)
        setOpenExpenseCommon(false)

    }

//button modals
    const [showExpenses, setShowExpenses] = useState(false);
    const [showIncome, setShowIncome] = useState(false);
    const [showExpensesCat, setShowExpensesCat] = useState(false);
    const [showExpensesData2, setShowExpensesData2] = useState(false);

    const [type, setType] = useState('');
    const [typeData2, setTypeData2] = useState('');

    // const[checkout, setCheckOut] = useState(false)
    //commonn expenses
    const[description, setDescription] = useState('')
    const[payment,     setPayment] = useState('')
    const[amount,      setAmount] = useState(0)
    const[months,      setMonths] = useState(1)

    const descriptionHandler = (e) => {
        setDescription(e.target.value)
    } 
    const paymentHandler = (e) => {
        setPayment(e.target.value)
    }
    const amountHandler = (e) => {
        setAmount(e.target.value)
    }
    const monthsHandler = (e) => {
        setMonths(e.target.value)
    }


    const data = {
            'category':'common',
            'description': description,
            'register':'',
            'payment': payment,
            'amount': amount,
            'months': months,
            'total': amount * months,
            'date': fecha
        }


        const[descriptiondata2, setDescriptiondata2] = useState('')
        const[paymentdata2,     setPaymentdata2] = useState('')
        const[amountdata2,      setAmountdata2] = useState(0)
        const[monthsData2,      setMonthsData2] = useState(1)
        const[registerdata2,      setRegisterdata2] = useState('')
    
        const descriptiondata2Handler = (e) => {
            setDescriptiondata2(e.target.value)
        } 
        const paymentdata2Handler = (e) => {
            setPaymentdata2(e.target.value)
        }
        const amountdata2Handler = (e) => {
            setAmountdata2(e.target.value)
        }
        const registerdata2Handler = (e) => {
            setRegisterdata2(e.target.value)
        }
    

    const data2 =  {
            'category':'regular',
            'description': descriptiondata2,
            'register':registerdata2,
            'payment': paymentdata2,
            'amount': amountdata2,
            'months': monthsData2,
            'total': amountdata2,
            'date': fecha
        }
    

      

    function createExpenseHandler() {
        dispatch(actionExpenses.createExpense(data))
        setShowExpensesCat(false)
        setShowExpensesData2(false)
        historyhandleClick()
    }

    function createExpenseRegularHandler() {
        dispatch(actionExpenses.createExpense(data2))
        setShowExpensesCat(false)
        setShowExpensesData2(false)
        historyhandleClick()
    }

    


    function deleteExpense(item) {
        dispatch(actionExpenses.deleteExpense(item))  
        historyhandleClickData2()
    }

    
    return (
        <>
        <Container className='mt-5'>
            <Row>
                <Col>
                    <div className='text-center'>
                        <Link to='/account'>
                            <img style={{width: 200}} src={logo} alt='...'  />
                        </Link>
                    </div>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col><h2 className='text-center mb-5'>Balance</h2></Col>
            </Row>            
            <Row>
                <Col>
                    <div className="d-grid gap-2 mb-3">
                        <Button style={{width: '100%'}} variant="outline-info" size="lg" onClick={showExpensesCommon} >
                            Expenses Common
                         </Button> 
                    </div>
                </Col>
                <Col>
                    <div className="d-grid gap-2 mb-3">
                        <Button style={{width: '100%'}} variant="outline-info" size="lg" onClick={showExpensesRegular} >
                            Expenses Regular
                         </Button> 
                    </div>
                </Col>
                <Col>
                <div className="d-grid gap-2 mb-3">
                       <Button style={{width: '100%'}} variant="outline-info" size="lg" onClick={() => setShowExpenses(true)}>
                            Expenses
                       </Button>
                       <Modal
                         show={showExpenses}
                         onHide={() => setShowExpenses(false)}
                         aria-labelledby="contained-modal-title-vcenter"
                         centered
                         backdrop="static"
                         keyboard={false}
                         >
                         <Modal.Header closeButton>
                           <Modal.Title id="example-custom-modal-styling-title">
                              Expenses
                           </Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                              We are working here sorry...
                         </Modal.Body>
                         <Modal.Footer>
                          <Button onClick={() => setShowExpenses(false)} variant="outline-info">Close</Button>
                        </Modal.Footer>
                       </Modal>
                    </div> 
                </Col>                
                <Col>
                <div className="d-grid gap-2 mb-3">
                       <Button style={{width: '100%'}} variant="outline-info" size="lg" onClick={() => setShowIncome(true)}>
                         Income
                       </Button>
                       <Modal
                         show={showIncome}
                         onHide={() => setShowIncome(false)}
                         aria-labelledby="contained-modal-title-vcenter"
                         centered
                         backdrop="static"
                         keyboard={false}
                         >
                         <Modal.Header closeButton>
                           <Modal.Title id="example-custom-modal-styling-title">
                           Income
                           </Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                           We are working here sorry...
                         </Modal.Body>
                         <Modal.Footer>
                          <Button onClick={() => setShowIncome(false)} variant="outline-info">Close</Button>
                        </Modal.Footer>
                       </Modal>
                    </div> 
                </Col>
            </Row>
        </Container>
       { openExpenseCommon === true && 
            <Container>
            <Row>
                <Col>
                <h5>Common Operating Expenses</h5>
                <div className='row'>
                <div className="d-grid gap-2 mb-3">
                       <Button style={{width: '100%'}} variant="outline-warning" size="md" onClick={() => setShowExpensesCat(true)}>
                            Add Expenses
                       </Button>
                       <Modal
                         show={showExpensesCat}
                         onHide={() => setShowExpensesCat(false)}
                         aria-labelledby="contained-modal-title-vcenter"
                         centered
                         backdrop="static"
                         keyboard={false}
                         >
                         <Modal.Header closeButton>
                           <Modal.Title id="example-custom-modal-styling-title">
                              Add Common Operating Expense
                           </Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                         <Form>
                              <Form.Group className="mb-3" controlId="formBasicDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control type="text" placeholder="Description" onChange={descriptionHandler} maxLength={25 } />
                                {/* <Form.Text className="text-muted">
                                  We'll never share your email with anyone else.
                                </Form.Text> */}
                              </Form.Group>
                              <Form.Group  className="mb-3" controlId="formBasicPayment" onChange={paymentHandler} >
                               <Form.Label>Methodo Payment</Form.Label>
                                  <Form.Control
                                    as="select"
                                    value={type}
                                    onChange={e => {
                                      setType(e.target.value);
                                    }}
                                  >
                                    <option value="">--Select--</option>
                                    <option value="Cash">Cash</option>
                                    <option value="Debit">Debit</option>
                                    <option value="Credit">Credit</option>
                                  </Form.Control>
                                </Form.Group>
                              <Form.Group className="mb-3" controlId="formBasicAmount" onChange={amountHandler}>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control type="number" placeholder="0.00" max={2}  />
                              </Form.Group>
                              <Form.Group  className="mb-3" controlId="formBasicMonths" onChange={monthsHandler} >
                               <Form.Label># Months</Form.Label>
                                  <Form.Control
                                    as="select"
                                    value={type}
                                    onChange={e => {
                                      setType(e.target.value);
                                    }}
                                  >
                                    <option value="">--Select--</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                  </Form.Control>                                  
                                </Form.Group>  
                              <Button variant="outline-info" type="button" onClick={createExpenseHandler} >
                                Add
                              </Button>
                            </Form>
                         </Modal.Body>
                         <Modal.Footer>
                          <Button onClick={() => setShowExpensesCat(false)} variant="outline-info">Close</Button>
                        </Modal.Footer>
                       </Modal>
                    </div> 
                </div>
                <table className="table table-striped table-sm">                    
                      <thead>
                        <tr className='text-center'>
                          <th scope="col">#</th>
                          <th scope="col">Description</th>
                          <th scope="col">payment</th>
                          <th scope="col">Amount</th>
                          <th scope="col">No Months</th>
                          <th scope="col">Total</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>                       
                        {
                            dataexpenses &&
                            dataexpenses.map(s => (
                                <tr key={s.id} className='text-center' >
                                  <th scope="row">{dataexpenses.indexOf(s) + 1}</th>
                                  <td className='text-left'>{s.description}</td>
                                  <td>{s.payment}</td>
                                  <td>{parseFloat(s.amount).toFixed(2)}</td>
                                  <td>{s.months}</td>
                                  <td>{parseFloat(s.total).toFixed(2)}</td>
                                  <td><Button variant="outline-danger" onClick={() => deleteExpense(s.id) } >Delete</Button></td>
                                </tr>
                            ))
                        }                                               
                         <tr>
                          <th colSpan="7" scope="row" className='text-center' >Resumen</th>
                          {/* <td colspan="6"></td> */}
                        </tr> 
                        <tr className='table-success' >
                           <th scope="row">#</th>
                           <td className='text-center' colSpan="4">Total</td>
                           <td className='text-center' colSpan="2">{`$ ${parseFloat(sumCash + sumDebit).toFixed(2)} `}</td>
                          </tr>
                        <tr  className='table-primary'>
                           <th scope="row">1</th>
                           <td className='text-center' colSpan="4">Total Cash</td>
                           <td className='text-center' colSpan="2">{`$ ${parseFloat(sumCash).toFixed(2)} `}</td>
                        </tr>
                        <tr  className='table-warning'>
                           <th scope="row">2</th>
                           <td className='text-center' colSpan="4">Total Debit</td>
                           <td className='text-center' colSpan="2">{`$ ${parseFloat(sumDebit).toFixed(2)} `}</td>
                          </tr>
                          <tr className='table-info' >
                           <th scope="row">3</th>
                           <td className='text-center' colSpan="4">Total Credit</td>
                           <td className='text-center' colSpan="2">{`$ ${parseFloat(sumCredit).toFixed(2)} `}</td>
                        </tr>
                      </tbody>
                    </table>
                </Col>
            </Row>
        </Container>}

        { openExpenseRegular === true && 
            <Container>
            <Row>
                <Col>
                <h5>Regular Operating Expenses</h5>
                <div className='row'>
                <div className="d-grid gap-2 mb-3">
                       <Button style={{width: '100%'}} variant="outline-warning" size="md" onClick={() => setShowExpensesData2(true)}>
                            Add Expenses
                       </Button>
                       <Modal
                         show={showExpensesData2}
                         onHide={() => setShowExpensesData2(false)}
                         aria-labelledby="contained-modal-title-vcenter"
                         centered
                         backdrop="static"
                         keyboard={false}
                         >
                         <Modal.Header closeButton>
                           <Modal.Title id="example-custom-modal-styling-title">
                              Add Regular Operating Expense
                           </Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                         <Form>
                              <Form.Group className="mb-3" controlId="formBasicDescriptionData2">
                                <Form.Label>Register No</Form.Label>
                                <Form.Control type="text" placeholder="Register" onChange={registerdata2Handler} maxLength={10 } />
                              </Form.Group>
                              <Form.Group className="mb-3" controlId="formBasicDescriptionData2">
                                <Form.Label>Description</Form.Label>
                                <Form.Control type="text" placeholder="Description" onChange={descriptiondata2Handler} maxLength={25 } />
                                {/* <Form.Text className="text-muted">
                                  We'll never share your email with anyone else.
                                </Form.Text> */}
                              </Form.Group>
                              <Form.Group  className="mb-3" controlId="formBasicPaymentData2" onChange={paymentdata2Handler} >
                               <Form.Label>Methodo Payment</Form.Label>
                                  <Form.Control
                                    as="select"
                                    value={typeData2}
                                    onChange={e => {
                                    //   console.log("e.target.value", e.target.value);
                                      setTypeData2(e.target.value);
                                    }}
                                  >
                                    <option value="">--Select--</option>
                                    <option value="Cash">Cash</option>
                                    <option value="Debit">Debit</option>
                                    <option value="Credit">Credit</option>
                                  </Form.Control>
                                </Form.Group>
                              <Form.Group className="mb-3" controlId="formBasicAmountData2" onChange={amountdata2Handler}>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control type="number" placeholder="0.00"   />
                              </Form.Group>                                
                              <Button variant="outline-info" type="button" onClick={createExpenseRegularHandler} >
                                Add
                              </Button>
                            </Form>
                         </Modal.Body>
                         <Modal.Footer>
                          <Button onClick={() => setShowExpensesData2(false)} variant="outline-info">Close</Button>
                        </Modal.Footer>
                       </Modal>
                    </div> 
                </div>
                <table className="table table-striped table-sm">                    
                      <thead>
                        <tr className='text-center'>
                          <th scope="col">#</th>
                          <th scope="col">Description</th>
                          <th scope="col">payment</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Register No:</th>
                          <th scope="col">Total</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>                       
                        {
                            dataexpensesRegular &&
                            dataexpensesRegular.map(x => (
                                <tr key={x.id} className='text-center' >
                                  <th scope="row">{dataexpensesRegular.indexOf(x) + 1}</th>
                                  <td className='text-left'>{x.description}</td>
                                  <td>{x.payment}</td>
                                  <td>{parseFloat(x.amount).toFixed(2)}</td>
                                  <td>{x.register}</td>
                                  <td>{parseFloat(x.total).toFixed(2)}</td>
                                  <td><Button variant="outline-danger" onClick={() => deleteExpense(x.id) } >Delete</Button></td>
                                  </tr>
                            ))
                        }                                               
                         <tr>
                          <th colSpan="7" scope="row" className='text-center' >Resumen</th>
                          {/* <td colspan="6"></td> */}
                        </tr> 
                        <tr className='table-success' >
                           <th scope="row">#</th>
                           <td className='text-center' colSpan="4">Total</td>
                           <td className='text-center' colSpan="2">{`$ ${parseFloat( sumCashRegular + sumDebitRegular + sumCreditRegular).toFixed(2)} `}</td>
                          </tr>
                        <tr  className='table-primary'>
                           <th scope="row">1</th>
                           <td className='text-center' colSpan="4">Total Cash</td>
                           <td className='text-center' colSpan="2">{`$ ${parseFloat(sumCashRegular).toFixed(2)} `}</td>
                        </tr>
                        <tr  className='table-warning'>
                           <th scope="row">2</th>
                           <td className='text-center' colSpan="4">Total Debit</td>
                           <td className='text-center' colSpan="2">{`$ ${parseFloat(sumDebitRegular).toFixed(2)} `}</td>
                          </tr>
                          <tr className='table-info' >
                           <th scope="row">3</th>
                           <td className='text-center' colSpan="4">Total Credit</td>
                           <td className='text-center' colSpan="2">{`$ ${parseFloat(sumCreditRegular).toFixed(2)} `}</td>
                        </tr>
                      </tbody>
                    </table>
                </Col>
            </Row>
        </Container>}
        </>
    )
}
export default Balance


