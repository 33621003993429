import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Axios from 'axios'
import { Link } from 'react-router-dom';
import './InvoiceLast.css'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Divider } from '@material-ui/core';
import { Button } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@material-ui/icons/Save';
import dbdata from "../db/dbdata.json"
import dbMakes from "../db/dbMakes.json"
import dbModels from "../db/dbModels.json"
import Getidinvoice from '../components/Getidinvoice';
import {businessname, bstreet, bcity, bemail, bphone} from '../models/European'
import logo from '../assets/image/logo.jpg'

const authentication = e => {    

 
    e.preventDefault()
    const form = e.target    

   const data = {
    "Idinvoice": form.Idinvoice.value,
    "Name": form.Name.value,
    "Phone": form.Phone.value,
    "Date": form.Date.value,
    "Brand": form.Brand.value,
    "Make": form.Make.value,
    "Model":form.Model.value,
    "Year": form.Year.value,
    "License":form.License.value,
    "Bin": form.Bin.value,
    "Balance": form.Balance.value,
    "Payment": form.Payment.value,
    "Mileage": form.Mileage.value,
    "Adate": form.Adate.value,
    "Ddate": form.Ddate.value,
    "Recommend": form.Recommend.value,
      "Lines_details": [         
          {
              "Des": form.Des1.value,
              "Pri": form.Pri1.value,
              "Qua": form.Qua1.value,
              "Lab":form.Lab1.value,
              "War": form.War1.value
          },
          { 
              "Des": form.Des2.value,
              "Pri": form.Pri2.value,
              "Qua": form.Qua2.value,
              "Lab":form.Lab2.value,
              "War": form.War2.value
          },
          {
            "Des": form.Des3.value,
            "Pri": form.Pri3.value,
            "Qua": form.Qua3.value,
            "Lab": form.Lab3.value,
            "War": form.War3.value
          },
          {
            "Des": form.Des4.value,
            "Pri": form.Pri4.value,
            "Qua": form.Qua4.value,
            "Lab": form.Lab4.value,
            "War": form.War4.value
          },
          {
            "Des": form.Des5.value,
            "Pri": form.Pri5.value,
            "Qua": form.Qua5.value,
            "Lab": form.Lab5.value,
            "War": form.War5.value
          },
          {
            "Des": form.Des6.value,
            "Pri": form.Pri6.value,
            "Qua": form.Qua6.value,
            "Lab": form.Lab6.value,
            "War": form.War6.value
          }
      ]
  }  

  Axios.post(process.env.REACT_APP_CREATEINVOICE, data)
    .then(r => {
      localStorage.setItem('token', r.data.token)
      window.location = "/"
    }).then(() => alert('The Invoice has been succesfully saved'))
    .catch(e => {
      alert('error al iniciar sesion')
    } )
  
  }


  

class InvoiceLast extends React.Component  { 
    constructor(props) {
        super(props);
        this.state = { pri1: 0 };
        this.state = { lab1: 0 };
        this.state = { qua1: 0 };
        this.state = { pri2: 0 };
        this.state = { lab2: 0 };
        this.state = { qua2: 0 };
        this.state = { pri3: 0 };
        this.state = { lab3: 0 };
        this.state = { qua3: 0 };
        this.state = { pri4: 0 };        
        this.state = { lab4: 0 };
        this.state = { qua4: 0 };
        this.state = { pri5: 0 };        
        this.state = { lab5: 0 };
        this.state = { qua5: 0 };
        this.state = { pri6: 0 };        
        this.state = { lab6: 0 };
        this.state = { qua6: 0 };
        this.state = { amo: 0 };
        // this.state = { dbt: false };
        // this.state = { cas: false };
      }
      
     
      myChangeHandlerpri1 = (event) => {
        this.setState({pri1: event.target.value});    
      }
      myChangeHandlerlab1 = (event) => {
        this.setState({lab1: event.target.value});    
      }
      myChangeHandlerqua1 = (event) => {
        this.setState({qua1: event.target.value});    
      }     
      myChangeHandlerpri2 = (event) => {
        this.setState({pri2: event.target.value});    
      }
      myChangeHandlerlab2 = (event) => {
        this.setState({lab2: event.target.value});    
      }
      myChangeHandlerqua2 = (event) => {
        this.setState({qua2: event.target.value});    
      }
      myChangeHandlerpri3 = (event) => {
        this.setState({pri3: event.target.value});    
      }
      myChangeHandlerlab3 = (event) => {
        this.setState({lab3: event.target.value});    
      }
      myChangeHandlerqua3 = (event) => {
        this.setState({qua3: event.target.value});    
      }
      myChangeHandlerpri4 = (event) => {
        this.setState({pri4: event.target.value});    
      }
      myChangeHandlerlab4 = (event) => {
        this.setState({lab4: event.target.value});    
      }
      myChangeHandlerqua4 = (event) => {
        this.setState({qua4: event.target.value});    
      }
      myChangeHandlerpri5 = (event) => {
        this.setState({pri5: event.target.value});    
      }
      myChangeHandlerlab5 = (event) => {
        this.setState({lab5: event.target.value});    
      }
      myChangeHandlerqua5 = (event) => {
        this.setState({qua5: event.target.value});    
      }
      myChangeHandlerpri6 = (event) => {
        this.setState({pri6: event.target.value});    
      }
      myChangeHandlerlab6 = (event) => {
        this.setState({lab6: event.target.value});    
      }
      myChangeHandlerqua6 = (event) => {
        this.setState({qua6: event.target.value});    
      }
      myChangeHandleramo = (event) => {
        this.setState({amo: event.target.value});    
      }
      print(){
        window.print();
    }

    render(){
      
    return(
        <>
        <Container style={{marginTop:'40px'}}>   
            <form onSubmit={authentication.bind()}>
        <Row className='mb-3'>
            <Col sm={3} className='text-center'> 
            <div >
                <Link to='/account'>
                 <img               
                style={{width: '300px', height:150}}
                src={logo}
               alt='..'  /> 
                </Link>
            </div>
             </Col>
            <Col sm={6} className="text-center" > 
            <h2 className="text-center">{businessname}</h2>
                <p className="text-center">{bstreet}<br/>
                {bcity}<br/>{bphone}</p>
            </Col>
            <Col sm={3} className="text-center"> 
            <h3 >Repair Invoice</h3>
            </Col>
        </Row>
        <Divider variant="middle" />
        <Divider className='mb-3' variant="middle" />
        <Row className='mb-3'>        
            <Col md={4}> 
              <Row>
                <Col md={4}>
                  <h5 style={{marginTop:12}}>Bill To:</h5>
                </Col>
                <Col >
                <TextField  id="Name" name='Name' type='text' label="Full Name" variant="standard" />
                </Col>
              </Row>
              <Row>
                <Col  md={4}>
                  <h5 style={{marginTop:12}}>Phone:</h5>
                </Col>
                <Col>
                <TextField id="Phone" name="Phone" type='number' label="Phone Number" variant="standard" />
                </Col>
              </Row>
              {/* <h4>Bill To:</h4>                
              <TextField  id="Name" name='Name' type='text' label="Full Name" variant="standard" />
              <TextField id="Phone" name="Phone" type='number' label="Phone Number" variant="standard" /> */}
            </Col>
            <Col md={{ span: 4, offset: 4 }}> 
                <Row>
                    <Col md={6}>
                    <h5>Invoice No:</h5>
                    </Col>
                    <Col md={6}>
                      <Getidinvoice />
                    {/* <TextField  id="Idinvoice" name="Idinvoice" type='number'  variant="standard" /> */}
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                    <h5>Date:</h5>
                    </Col>
                    <Col md={5}>
                    <TextField  id="Date" name="Date" type='date'  variant="standard" />
                    </Col>
                </Row>
            </Col>
            
        </Row>
        
        <h4>Car Information</h4>
        {/* <Divider variant="middle" /> */}
        <Row className='mb-3'>            
            <Col md={4}>
                <Row>
                    <Col md={5}>
                    <br/>
                    <h5>Car Name:</h5>                            
                    </Col>
                    <Col md={7}>
                    <Autocomplete
                      id="idBrand"
                      options={dbMakes.makes}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 160 }}
                      renderInput={(params) => <TextField {...params} id="Brand" name="Brand" type='text' label=" "  variant="standard" />}
                    /> 
                    </Col>
                </Row> 
                <Row>
                    <Col md={5}>
                    <br/>
                    <h5>Year:</h5>                            
                    </Col>
                    <Col md={7}>
                    <Autocomplete
                      id="idYear"
                      options={dbdata.year}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 160 }}
                      renderInput={(params) => <TextField {...params} id="Year" name="Year" type='text' label=" " variant="standard" />}
                    /> 
                    </Col> 
                </Row>
                <Row>
                    <Col md={5}>
                    <br/>
                    <h5 style={{ fontSize:18 }}>Mileage:</h5>                            
                    </Col>
                    <Col md={7}>
                    <TextField style={{ width: 160 }} id="Mileage" name="Mileage" type='number' label=" " variant="standard" />
                    </Col> 
                </Row>                                              
            </Col>  
            <Col md={4}>
            <Row>
                    <Col md={4}>
                    <br/>
                    <h5>Make:</h5>                            
                    </Col>
                    <Col md={8}>
                    <Autocomplete
                      id="idMake"
                      options={ dbMakes.makes}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 200 }}
                      renderInput={(params) => <TextField {...params} id="Make" name="Make" type='text' label=" " variant="standard" />}
                    /> 
                    </Col>
                </Row>                 
                <Row>
                    <Col md={4}>
                    <br/>
                    <h5>LPN:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField  style={{ width: 200 }} id="License" name="License" type='text' label="License Plate Number" variant="standard" />
                  
                    </Col>
                </Row> 
                <Row>
                    <Col md={4}>
                    <br/>
                    <h5 style={{ fontSize:18 }}>Date in:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField style={{ width:220, marginTop:17 }} size='small'  id="Adate" name="Adate" type='datetime-local'  variant="standard" />
                    </Col> 
                </Row>                                 
            </Col> 
            <Col md={4}>
            <Row>
                    <Col md={4}>
                    <br/>
                    <h5>Model:</h5>                            
                    </Col>
                    <Col md={8}>
                    <Autocomplete
                      id="idModel"
                      options={ dbModels.models}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 180 }}
                      renderInput={(params) => <TextField {...params} id="Model" name="Model" type='text' label=" " variant="standard" />}
                    /> 
                    </Col>
                </Row> 
                <Row>
                    <Col md={4}>
                     <br/>
                    <h5>VIN:</h5>                            
                    </Col>
                    <Col md={8}>
                    <TextField  style={{ width: 180 }} id="Bin" name="Bin" type='text' label=" " variant="standard" />                    
                    </Col>
                </Row>  
                <Row>
                    <Col md={4}>
                    <br/>
                    <h5 style={{ fontSize:17 }}>Date out:</h5>                            
                    </Col>
                    <Col md={7}>
                    <TextField  style={{ width:220, marginTop:17 }} size='small'  id="Ddate" name="Ddate" type='datetime-local'  variant="standard" />
                    </Col> 
                </Row>                                 
            </Col>           
        </Row>
        <Row className='mb-5' >
        <table className="table  table-sm ">
            <thead>
              <tr className='text-center'>
                <th style={{width:'20px', borderBottomColor:'black'}}>#</th>
                <th style={{width:'330px', borderBottomColor:'black'}}>Description</th>
                <th style={{width:'90px', borderBottomColor:'black'}}>Price $</th>
                <th style={{width:'90px', borderBottomColor:'black'}}>Quantity</th>
                <th style={{width:'90px', borderBottomColor:'black'}}>Labor $</th>
                <th style={{width:'110px', borderBottomColor:'black'}}>Warranty</th>
                <th style={{width:'100px', borderBottomColor:'black'}}>Total $</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center'>1</td>
                <td><TextField multiline  fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}} id="Des1" name="Des" type='text' label="" variant="standard" /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center'}}} id="Pri1" name="Pri" type='number' label="" variant="standard" onChange={this.myChangeHandlerpri1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua1" name="Qua" type='number' label="" variant="standard" onChange={this.myChangeHandlerqua1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab1" name="Lab" type='number' label="" variant="standard" onChange={this.myChangeHandlerlab1} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War1" name="War" type='text' label="" variant="standard"  /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center' >{((this.state.pri1 ?  parseInt(this.state.pri1) * parseInt(this.state.qua1) : 0) + (this.state.lab1 ? parseInt(this.state.lab1) : 0 )).toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center'>2</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des2" name="Des" type='text' label="" variant="standard" /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri2" name="Pri" type='number' label="" variant="standard" onChange={this.myChangeHandlerpri2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua2" name="Qua" type='number' label="" variant="standard" onChange={this.myChangeHandlerqua2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab2" name="Lab" type='number' label="" variant="standard" onChange={this.myChangeHandlerlab2} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField  inputProps={{min: 0, style: { textAlign: 'center' }}} id="War2" name="War" type='text' label="" variant="standard"  /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center' >{((this.state.pri2 ?  parseInt(this.state.pri2) * parseInt(this.state.qua2) : 0) + (this.state.lab2 ? parseInt(this.state.lab2) : 0 )).toFixed(2)}</td>
              </tr>
              <tr>
                <td style={{verticalAlign: 'middle' }} className='text-center' >3</td>
                <td ><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des3" name="Des" type='text' label="" variant="standard" /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri3" name="Pri" type='number' label="" variant="standard" onChange={this.myChangeHandlerpri3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua3" name="Qua" type='number' label="" variant="standard" onChange={this.myChangeHandlerqua3} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab3" name="Lab" type='number' label="" variant="standard"  onChange={this.myChangeHandlerlab3}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War3" name="War" type='text' label="" variant="standard" /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((this.state.pri3 ?  parseInt(this.state.pri3) * parseInt(this.state.qua3) : 0) + (this.state.lab3 ? parseInt(this.state.lab3) : 0 )).toFixed(2)}</td>

              </tr> 
             
                <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>4</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des4" name="Des" type='text' label="" variant="standard"  /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri4" name="Pri" type='number' label="" variant="standard"  onChange={this.myChangeHandlerpri4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua4" name="Qua" type='number' label="" variant="standard" onChange={this.myChangeHandlerqua4} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab4" name="Lab" type='number' label="" variant="standard"   onChange={this.myChangeHandlerlab4}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War4" name="War" type='text' label="" variant="standard" /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((this.state.pri4 ?  parseInt(this.state.pri4) * parseInt(this.state.qua4) : 0) + (this.state.lab4 ? parseInt(this.state.lab4) : 0 )).toFixed(2)}</td>
              </tr> 
              
              <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>5</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des5" name="Des" type='text' label="" variant="standard"  /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri5" name="Pri" type='number' label="" variant="standard"  onChange={this.myChangeHandlerpri5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua5" name="Qua" type='number' label="" variant="standard" onChange={this.myChangeHandlerqua5} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab5" name="Lab" type='number' label="" variant="standard"   onChange={this.myChangeHandlerlab5}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War5" name="War" type='text' label="" variant="standard" /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((this.state.pri5 ?  parseInt(this.state.pri5) * parseInt(this.state.qua5) : 0) + (this.state.lab5 ? parseInt(this.state.lab5) : 0 )).toFixed(2)}</td>
              </tr> 
             
               <tr >
                <td style={{verticalAlign: 'middle' }} className='text-center'>6</td>
                <td><TextField multiline fullWidth={true} inputProps={{min: 0, style: { textAlign: 'left' }}}  id="Des6" name="Des" type='text' label="" variant="standard"  /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Pri6" name="Pri" type='number' label="" variant="standard"  onChange={this.myChangeHandlerpri6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Qua6" name="Qua" type='number' label="" variant="standard" onChange={this.myChangeHandlerqua6} /></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="Lab6" name="Lab" type='number' label="" variant="standard"   onChange={this.myChangeHandlerlab6}/></td>
                <td style={{verticalAlign: 'middle' }}><TextField inputProps={{min: 0, style: { textAlign: 'center' }}} id="War6" name="War" type='text' label="" variant="standard" /></td>
                <td style={{verticalAlign: 'middle' }} className='text-center'>{((this.state.pri6 ?  parseInt(this.state.pri6) * parseInt(this.state.qua6) : 0) + (this.state.lab6 ? parseInt(this.state.lab6) : 0 )).toFixed(2)}</td>
              </tr>                     
              <tr>
                <td className='text-center' style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderBottomColor:'white'}}></td>
                <td style={{borderColor:'black'}} className='text-left' ><h6><strong>Total $</strong></h6></td>
                <td style={{borderColor:'black'}} className='text-center' >
                    <h6>
                    <strong>
                    {
                        (((this.state.pri1 ? parseInt(this.state.pri1) * parseInt(this.state.qua1) : 0) + (this.state.lab1 ? parseInt(this.state.lab1) : 0 )) + 
                        ((this.state.pri2 ?  parseInt(this.state.pri2) * parseInt(this.state.qua2) : 0) + (this.state.lab2 ? parseInt(this.state.lab2) : 0 )) +
                        ((this.state.pri3 ?  parseInt(this.state.pri3) * parseInt(this.state.qua3) : 0) + (this.state.lab3 ? parseInt(this.state.lab3) : 0 )) + 
                        ((this.state.pri4 ?  parseInt(this.state.pri4) * parseInt(this.state.qua4) : 0) + (this.state.lab4 ? parseInt(this.state.lab4) : 0 )) +
                        ((this.state.pri5 ?  parseInt(this.state.pri5) * parseInt(this.state.qua5) : 0) + (this.state.lab5 ? parseInt(this.state.lab5) : 0 )) + 
                        ((this.state.pri6 ?  parseInt(this.state.pri6) * parseInt(this.state.qua6) : 0) + (this.state.lab6 ? parseInt(this.state.lab6) : 0 ))).toFixed(2)

                    }
                    </strong>
                    </h6>
                </td>
              </tr>   
              <tr>
                <td className='text-center' style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}} ></td>
                <td style={{borderColor:'white'}} ></td>
                <td style={{borderBottomColor:'white'}} ></td>
                <td style={{borderColor:'black'}} className='text-left'><h6><strong>Paid Amount $</strong></h6></td>
                <td style={{borderColor:'black'}} className='text-center' ><strong>
                  <TextField inputProps={{min: 0, style: { textAlign: 'center' }}}  
                  id="amo" type='number' label="" 
                  name="amo"
                  placeholder="  0.00"
                  variant="standard"  
                  onChange={this.myChangeHandleramo}/></strong></td>
              </tr> 
              <tr>
                <td className='text-center' style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}><Autocomplete
                      id="idpayment"
                      options={ dbdata.payment}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 160 }}
                      renderInput={(params) => <TextField {...params} id="Payment" name="Payment" type='text' label="Payment methodo" variant="standard" />}
                    /> </td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderColor:'white'}}></td>
                <td style={{borderBottomColor:'white'}}></td>
                <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-left'><h6><strong>Balance Due $</strong></h6></td>
                <td style={{borderColor:'black', verticalAlign:'middle'}} className='text-center' ><strong>
                <TextField inputProps={{min: 0, style: { textAlign: 'center', fontWeight: 'bold', fontSize: "large" }}} id="Balance" name="Balance"  type='text' label="" variant="standard" 
                value= 
                {
                  ((((this.state.pri1 ? parseInt(this.state.pri1) * parseInt(this.state.qua1) : 0) + (this.state.lab1 ? parseInt(this.state.lab1) : 0 )) + 
                  ((this.state.pri2 ?  parseInt(this.state.pri2) * parseInt(this.state.qua2) : 0) + (this.state.lab2 ? parseInt(this.state.lab2) : 0 )) +
                  ((this.state.pri3 ?  parseInt(this.state.pri3) * parseInt(this.state.qua3) : 0) + (this.state.lab3 ? parseInt(this.state.lab3) : 0 )) + 
                  ((this.state.pri4 ?  parseInt(this.state.pri4) * parseInt(this.state.qua4) : 0) + (this.state.lab4 ? parseInt(this.state.lab4) : 0 )) + 
                  ((this.state.pri5 ?  parseInt(this.state.pri5) * parseInt(this.state.qua5) : 0) + (this.state.lab5 ? parseInt(this.state.lab5) : 0 )) + 
                  ((this.state.pri6 ?  parseInt(this.state.pri6) * parseInt(this.state.qua6) : 0) + (this.state.lab6 ? parseInt(this.state.lab6) : 0 ))) - (this.state.amo ? parseInt(this.state.amo) : 0)).toFixed(2)

              }
                /></strong>                  
                </td>
              </tr>  
            </tbody>
            </table>
        </Row>       
        <Row className='mb-2'>
            <Col md={6} className='text-center'>
                <h5>Customer signature</h5>
            </Col>
            <Col md={6} className='text-center'>
                <h5>{businessname}</h5>                
            </Col>
        </Row>    
        <Row className='mb-5'>           
            <Col md={12} className='text-left'>
                <h6>MECHANICS RECOMMENDATIONS:</h6>
                {/* <textarea  
                  style={{ fontSizeAdjust:15, width:'100%', height:'150px',  padding: "12px 20px", borderColor:'white',  fontSize: "16px", resize: "none" }}
                   id="Recommend" 
                   name="Recommend" 
                   type='text' 
                />    */}
                <textarea  
                  style={{ borderColor:'white', width:'100%',  height:'100px', resize:'vertical'}}
                   id="Recommend" 
                   name="Recommend" 
                   type='text' 
                />               
                  
            </Col>
        </Row> 
        <Row>
            <Col md={4} className='text-center' >
                <span><strong>{bemail}</strong></span>
            </Col>
            <Col md={4} className='text-center'>
                <h5 ><strong>Thanks for choosing us.</strong></h5>
            </Col>
            <Col md={4} >
            <Row>
                <Col>
                   <Button                                
                        type="submit"
                         variant="outlined"
                         color="primary"
                         size="small"
                         className="primary"
                         startIcon={<SaveIcon />}                  
                        >
                         Save
                    </Button>
                </Col>
                <Col>
                    <Button    
                           onClick={this.print}  
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="primary"
                            startIcon={<PrintIcon />}                  
                           >
                            Print
                       </Button>
                </Col>

              </Row>
            </Col>
        </Row>
        </form>
      </Container>
      </>
)}}
export default InvoiceLast